<template>
    <div class="portal_playbooks">
        <!-- <div class="result_wpr new">
            <div class="actions mt-2">
                <ul>
                    <li class="optionDrops contacts-tabs" @click="filterList = !filterList" v-click-outside="closeFilters">
                        Filter<i class="fas fa-angle-down"></i>
                        <div class="dropdown_wpr" :class="filterList ? 'active' : ''">
                            <ul>
                                <li v-for="(category, c) of playbookCategories" :key="c" @click="handleCategoryFilter(category.id)">{{ category.name }}</li>
                            </ul>
                        </div>
                    </li>
                    <li class="optionDrops search_area" :class="{'active': searchField}">
                        <input type="text" placeholder="Search" :disabled="assetsLoader" ref="search" @input="isTyping = true" v-model.trim="params.search" />
                        <button class="search_btn" @click="searchField = !searchField;">
                            <i class="fas fa-search"></i>
                        </button>
                    </li>
                </ul>
            </div>
        </div> -->
        <div v-if="playbooks.total" class="list_container">
            <ul class="client_productList">
                <li v-for="(playbook, p) of playbooks.data" :key="p">
                    <div class="product_card">
                        <img v-if="playbook.dashboard_thumb" :src="playbook.dashboard_thumb" alt="" class="product_thumb">
                        <img v-else src="@/assets/images/thumb/default-playbook.svg" alt="" class="product_thumb">
                        <div class="product_cont playbook-desc">
                            <!-- <div class="status">
                                <span :style="`background-color: ${playbook.progressbar_color}; width: ${25}%;`"></span>
                                <label>25%</label>
                            </div> -->
                            <h4 class="pointer">{{ playbook.title.length > 35 ? playbook.title.substr(0, 35)+'...' : playbook.title }}</h4>
                            <p>{{ playbook.description && playbook.description.length > 70 ? playbook.description.substr(0, 70)+'...' : playbook.description }}</p>
                            <a href="javascript:void(0);" class="primary_btn custom_element">Open Playbook</a>
                        </div>
                    </div>
                </li>
            </ul>
            <div class="pagination mt-2">
                <pagination v-model="params.page" :pages="playbooks.last_page" @update:modelValue="handlePagination" />
            </div>
        </div>
        <div class="empty_section" v-else>
            <img src="@/assets/images/empty_habit_tracking.svg" alt="Empty">
            <h1 class="text-center">Your course list is looking a bit empty at the moment. No worries, though! Just reach out to your coach to get some exciting playbooks assigned!</h1>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'Client Portal Playbook',

        data () {
            return {
                filterList: false,
                searchField: false,
                params: {
                    per_page: 8,
                    page: 1,
                    category_id: null,
                    search: '',
                },
                isTyping: false,
            };
        },

        props: {
            title: String,
            search: {
                type: String,
                default: '',
            },
            category: {
                type: Number,
                default: 0
            },
        },

        watch: {
            'params.search' (val) {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            search (search) {
                const vm = this;

                vm.params.search = search;
                vm.isTyping = true;
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length >= 2) {
                            vm.params.page = 1;
                            vm.getPlaybooks(vm.params);
                        }
                        if (vm.params.search.length === 0) {
                            vm.params.page = 1;
                            vm.getPlaybooks(vm.params);
                        }
                    }
                }
            },

            category (category) {
                const vm = this;

                vm.handleCategoryFilter(category);
            },
        },

        computed: mapState({
            playbooks: state => state.clientPortalModule.playbooks,
            assetsLoader: state => state.clientPortalModule.assetsLoader,
            playbookCategories: state => state.clientPortalModule.playbookCategories,
        }),

        mounted () {
            const vm = this;

            if (!vm.playbooks.total) {
                vm.getPlaybooks(vm.params);
            } else {
                vm.params.page = vm.playbooks.current_page;
            }

            if (vm.playbookCategories.length == 0) {
                vm.getPlaybookCategories();
            }
        },

        methods: {
            ...mapActions({
                getPlaybooks: 'clientPortalModule/getPlaybooks',
                getPlaybookCategories: 'clientPortalModule/getPlaybookCategories',
            }),

            handlePagination (page) {
                const vm = this;

                vm.params.page = page;
                vm.getPlaybooks(vm.params);
            },

            closeFilters () {
                const vm = this;

                vm.filterList = false;
            },

            handleCategoryFilter (id) {
                const vm = this;

                vm.params.category_id = id;
                vm.params.page = 1;
                vm.getPlaybooks(vm.params);
            },
        },
    }
</script>

<style scoped>
    .portal_playbooks{
        margin-bottom: 40px;
    }
    .portal_playbooks .client_productList {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px 20px -10px;
    }

    .portal_playbooks .client_productList li {
        flex: 0 0 25%;
        padding: 15px 10px;
    }

    .portal_playbooks .client_productList .product_card {
        width: 100%;
    }

    /* .inner_pages .actions.filter_bar {
        border-radius: 5px;
        border: 1px solid #eaeaea;
    }
    .inner_pages .actions.filter_bar li.option_drop {
        max-width: 250px;
    }

    .inner_pages .actions.filter_bar .dropdown_wpr {
        width: 100%;
    } */

    .contacts-tabs :deep(.dropdown_wpr){
        border-radius: 6px;
        overflow: hidden;
    }

    .client_productList .product_card .product_cont p {
        margin-bottom: 45px;
    }

    .filter_bar li {
        position: relative;
    }
    .empty_section {
        /* border: 1px solid #D9D9D9; */
        border-radius: 10px;
        height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px 15px;
        margin: 20px 0;
    }
    .empty_section img{
        max-height: 90px;
        width: auto;
        margin-bottom: 30px;
    }
    .empty_section h1 {
        font-family: 'Inter', sans-serif;
        font-size: 15px;
        line-height: 25px;
        font-weight: 400;
        color: #2c3e50;
        /* font-style: italic; */
    }
    .result_wpr.new .actions{
        margin-bottom: 0;
    }
    .product_cont .status {
        min-height: 14px;
        flex-grow: 0;
        border-radius: 7px;
        background: #ddd;
        position: relative;
        max-width: 100%;
        margin-bottom: 15px;
        overflow: hidden;
    }

    .product_cont .status span {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        background: var(--var-portal-tab-background-color);
        z-index: 1;
    }
    .product_cont .status label {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #121525;
        height: 100%;
        z-index: 2;
    }
    .client_productList .product_card .product_cont .primary_btn{
        margin-right: 0;
        margin-left: 0;
    }
    @media(max-width: 1399px){
        .playbook-desc h4 {
            height: 38px;
        }
        .playbook-desc p {
            height: 48px;
        }
    }
    @media(max-width: 1199px){
        .result_wpr.new .actions > ul{
            height: 51px !important;
        }
    }
    @media(max-width: 767px){
        .result_wpr.new .actions > ul{
            height: auto !important;
        }
        .result_wpr.new .actions{
            margin-bottom: 3px;
        }
        .portal_playbooks .client_productList{
            margin: 0 -12px 20px -12px;
            padding: 0;
        }
        .portal_playbooks .client_productList li{
            padding: 12px;
        }
    }
    @media(max-width: 650px){
        .list_container{
            max-width: 500px;
            margin: 0 auto;
        }
    }
    @media(max-width: 499px){
        .portal_playbooks .result_wpr.new .actions > ul li.optionDrops.contacts-tabs{
            padding: 0 5px;
        }
        .empty_section h1{
            font-size: 13px;
            line-height: 23px;
        }
    }
    @media(max-width: 450px){
        .list_container{
            max-width: 280px;
            margin: 0 auto;
        }
        .result_wpr.new .actions > ul li.search_area{
            width: inherit;
        }
        .result_wpr.new .actions > ul li.search_area input[type=text]{
            width: 0;
        }
        .result_wpr.new .actions > ul li.search_area.active input[type=text]{
            width: 150px;
        }
    }
</style>
